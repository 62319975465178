<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content">
         <div class="flex"><h4 class="global_head_title">编辑线索</h4></div>
               <div class="content_row flex">
                <label class="info" for="">线索名称<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="cont.name" size="large" placeholder="请输入线索名称"/>
                </div>
            </div>
            
            <div class="content_row flex">
                <label class="info" for="">客户名称</label>
                  <div class="content_right">
                    <a-input v-model="cont.customerid" size="large" placeholder="请输入客户名称"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">联系人</label>
                  <div class="content_right">
                    <a-input v-model="cont.customeruser" size="large" placeholder="请输入联系人"/>
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">联系方式<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="cont.customertel" size="large" placeholder="请输入联系方式"/>
                </div>
            </div>
             <div class="content_row flex" >
                <label class="info" for="">状态</label>
                  <div class="content_right">
                    <a-select v-model="status"  class="ant-select-lg" style="background:#fff">
                        <a-select-option :value="index" v-for="(item,index) in statusList" :key="index">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">线索描述</label>
                <div class="content_right">
                    <a-textarea v-model="cont.remarks" placeholder="请输入线索描述" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">跟进人</label>
                  <div class="content_right">
                    <!-- <a-input v-model="cont.sname" disabled size="large" placeholder="请输入跟进人"/> -->
                    <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择跟进人" />
            
                    <!-- <a-select v-model="onUid" class="ant-select-lg">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">
                            {{item.name}}
                        </a-select-option>
                    </a-select> -->
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
           <div class="content_row flex giveUp" >
                <label class="info" for="">放弃原由</label>
                  <div class="content_right">
                    <span>
                        {{cont.reason}}
                    </span>
                </div>
            </div>
            </div>
        </div>
          <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
    </div>
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import {requestXml,isMobile} from '../../../assets/js/request';
export default {
    components: {
        Staff,
    },
    data() {
        return {
           status:'',
            Cid:'',  //线索id
            cont:'', //内容
            statusList: ['未处理','已跟进','转商机','已放弃'],
            staff:[], //跟进人
            onUid:'',
            staffFlag: false,
            onStaff: {
                name: "",
                userid: "",
            },
       };
    },
    mounted: function(){
        this.Cid = this.$route.query.id;
        // this.getStaff();
        if(this.Cid){
            this.getDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },
    watch:{
        taskId: function(newVal){ 
            this.id = newVal;
        },
    },
    methods: {
        // 获取任务详情
        getDetail() {
            requestXml("/jieb/clue/cluedetail","GET",(res) => {
                if(res){
                    this.cont =res;
                    this.status=this.statusList[Number(res.status)];
                    this.onUid=res.uid;
                    this.onStaff.userid = res.uid;
                    this.onStaff.name = res.responsibility_name;
                }
            },{id: this.Cid})
        },
        // 成员
        getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
               
            })
        },
         // 负责人-输入框聚焦是清除其焦点
        focus(e) {
            e.srcElement.blur();
        },
        // 分配完
        updateStaffFlag(data) {
        // console.log(data,"dasds")
            if (data != 1) {
                this.onStaff = data;
            }
            this.staffFlag = false;
        },
        // 完成
        submit(){
            requestXml("/jieb/clue/saveclue","POST",(res) => {
                if(res=='success'){
                //   this.$message.success('保存成功');
                }else{
                    //  this.$message.success(res.msg);
                }
            },{"id": this.Cid,'uid': this.onStaff.userid,'name':this.cont.name,'status':this.status,'customerid':this.cont.customerid,'customeruser':this.cont.customeruser,'customertel':this.cont.customertel,'remarks':this.cont.remarks})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    .giveUp{
        margin-top: 50px!important;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
